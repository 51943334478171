// Colors
$gray-1: #262626;
$gray-2: #434343;
$gray-3: #555555;
$gray-4: #7b7b7b;
$gray-5: #9d9d9d;
$gray-6: #c4c4c4;
$gray-7: #d9d9d9;
$gray-8: #e9e9e9;
$gray-9: #f5f5f5;

// Button
$btn-tertiary-border-color: $semi-dark;
$btn-tertiary-color: $secondary-color;

// input
$input-border-color: #dddddd;
$input-height: 40px;
$input-font-size: 14px;
$input-placeholder: #333;
$input-font-color: #000;
$input-label-font-size: 14px;
$form-control-mb: 20px;

//tables
$horizontal-thead-border-color: #9d9d9d;
$table-even-color: #f9f5eb;

// Menu
$small-menu-width: 64px;
