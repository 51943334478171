/**=====================
    Inventory CSS Start
==========================**/
body {
  background: $body;
  @include font-size(14, 18, 0);
  font-family: "Open Sans";
}

.arabic-font {
  font-family: "Janna LT";
}
// Title
.title {
  @include font-size(32, 40, 0.02);
  font-weight: bold;
  color: #000;
}

.page-title {
  @include font-size(24, 32, 0.02);
  font-weight: bold;
  color: #000;
}

.modal-title {
  @include font-size(18, 24, 0.02);
  font-weight: bold;
  color: #000;
}

.fs-16 {
  @include font-size(16, 22, 0.02);
  font-weight: 300;
}

.fs-18 {
  @include font-size(16, 22, 0.02);
}

.fs-14 {
  @include font-size(14, 14, 0.02);
  font-weight: 300;
}

.small-text {
  @include font-size(12, 16, 0.02);
  font-weight: 300;
}

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-success {
  color: $success-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-info {
  color: $info-color !important;
}

.text-black {
  color: #000 !important;
}

.text-dark {
  color: $font-color !important;
}

.bg-primary-gradient,
.bg-primary-gradient:hover {
  background: $primary-color;
  background: linear-gradient(
    90deg,
    rgba(44, 69, 146, 1) 0%,
    rgba(11, 39, 124, 1) 100%
  );
}

.bg-success-gradient,
.bg-success-gradient:hover {
  background: $success-color;
  background: linear-gradient(
    90deg,
    rgba(0, 153, 112, 1) 0%,
    rgba(2, 117, 86, 1) 100%
  );
}

.border,
.border-right,
.border-left,
.border-top,
.border-bottom {
  border-color: $input-border-color;
}

// Form
label {
  font-size: $input-label-font-size;
  color: $input-font-color;
}

.form-control {
  min-height: $input-height;
  border-color: $input-border-color !important;
  color: $input-font-color;
  font-size: $input-font-size !important;
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 0;
  &.form-control-sm {
    padding: 8px;
  }
  &.select-control {
    padding: 0 20px;
    &.form-control-sm {
      padding: 8px;
    }
  }
  ::placeholder {
    color: $input-placeholder;
    font-size: $input-font-size;
  }
  &.valid {
    border-color: $success-color !important;
  }

  &.invalid {
    border-color: $danger-color !important;
  }
}

textarea {
  &.form-control {
    min-height: 80px;
  }
}

.valid-feedback {
  color: $success-color;
  font-size: 12px;
}
.invalid-feedback {
  color: $danger-color;
  font-size: 12px;
}
.react-datepicker-popper {
  z-index: 10;
}

//Page Wrapper
body {
  .page-wrapper {
    .login-bg {
      background: $primary-color;
      padding: 10% 0px;
      border-radius: 0.75rem;
      position: absolute;
      left: 16px;
      top: 16px;
      width: calc(100% - 36px);
    }
    .authentication-box {
      // background: url(../../images/background.png) repeat-x;
      background-position: bottom;
      background-size: 30%;
      position: relative;
      .login-logo {
        max-width: 340px;
        text-align: center;
        margin-bottom: 40px;
        img {
          max-width: 280px;
          max-height: 150px;
        }
      }
      .page-title {
        color: #fff;
        margin-bottom: 30px;
      }
      .login-card {
        box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
        max-width: 340px;
        min-width: 340px;
        border-radius: 0.5rem;
        .input-group {
          &-text {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background: transparent;
            border: 0;
            z-index: 9;
            cursor: pointer;
          }
          .form-control {
            padding-right: 48px;
          }
        }
      }
    }
    .page-main-header {
      // backdrop-filter: blur(20px);
      // box-shadow: inset 0px -1px 1px #e7ebf0;
      // background-color: rgba(255, 255, 255, 0.72);
      background-color: white;
      @include for-phone-only {
        top: 29px;
      }
      .user-name {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-size(14, 18, 0);
        font-weight: bold;
      }
    }
    .page-body-wrapper {
      //PAGEBODY
      .page-body {
        background: $body;
        padding-top: 30px;
        //tabls
        .table {
          .fa {
            font-size: 16px;
          }
          &-bordered {
            tr {
              td,
              th {
                border-color: $input-border-color;
              }
            }
          }
          &-inventory {
            background-color: $white;
            color: $black;
            thead {
              tr {
                td,
                th {
                  font-weight: 600;
                  font-size: 14px;
                  border-bottom: 1px solid $horizontal-thead-border-color;
                  min-height: 49px;
                  position: relative;
                  cursor: pointer;
                  // ::after {
                  //   content: "\f0dd";
                  //   font: normal normal normal 14px/1 FontAwesome;
                  //   position: absolute;
                  //   bottom: 17px;
                  //   right: 10px;
                  //   color: $gray-5;
                  // }
                  i {
                    color: $secondary-color;
                  }
                  .noshorting {
                    position: relative;
                    i {
                      position: absolute;
                      top: 1px;
                      color: $gray-5;
                      &:nth-child(2) {
                        top: 9px;
                      }
                    }
                  }
                }
              }
            }
            tbody {
              tr {
                td,
                th {
                  border-bottom: 1px solid $horizontal-border-color;
                  font-size: 14px;
                  color: $black;
                  min-height: 49px;
                  vertical-align: middle;
                  .badge-pill {
                    padding: 8px 14px;
                  }
                }
                &:nth-child(even) {
                  background: $table-even-color;
                }
              }
            }
          }
        }
        .rdt_TableRow {
          min-height: 50px;
          border-bottom-color: $horizontal-border-color;
          &:nth-child(even) {
            background: #f9f5eb;
          }
          &:hover {
            background: darken($color: #f9f5eb, $amount: 10%);
          }
          .rdt_TableCell {
            font-size: $body-font-size;
          }
          .rdt_TableCol_Sortable {
            font-size: 10px;
          }
        }
        //Cards
        .card {
          border-radius: 2px;
        }
      }
    }
  }

  // Form Control
  .form-group {
    @extend .text-left;
    .form-control {
      min-height: $input-height;
      border-color: $input-border-color !important;
      color: $input-font-color;
      font-size: $input-font-size !important;
      border-radius: 2px;
      padding: 20px;
      margin-bottom: 0;
      &.form-control-sm {
        padding: 8px;
      }
      &.select-control {
        padding: 0 20px;
      }
      ::placeholder {
        color: $input-placeholder;
        font-size: $input-font-size;
      }
      &.valid {
        border-color: $success-color !important;
      }

      &.invalid {
        border-color: $danger-color !important;
      }
    }
    textarea {
      &.form-control {
        min-height: 80px;
      }
    }
    .valid-feedback {
      color: $success-color;
      font-size: 12px;
    }
    .invalid-feedback {
      color: $danger-color;
      font-size: 12px;
    }
    .react-datepicker-popper {
      z-index: 10;
    }
  }

  .dropzone {
    border-color: $input-border-color;
    border-radius: 2px;
    min-height: 80px;
    padding: 10px;
    height: 80px;
  }

  .thumbnail-delete {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    background: $danger-color;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 8px;
  }

  /*Buttons*/
  .btn {
    letter-spacing: 0;
    &.h-32 {
      height: 32px;
    }
    &-inventory {
      padding: $btn-padding;
      height: $btn-height;
      line-height: 24px;
      letter-spacing: 0.2px;
    }
    &-tertiary {
      padding: $btn-padding;
      height: $btn-height;
      line-height: 24px;
      letter-spacing: 0.2px;
      background: $white;
      color: $btn-tertiary-color;
      border: 1px solid $btn-tertiary-border-color;
      &:hover {
        text-decoration: underline;
        color: $btn-tertiary-color;
      }
    }
    &-sm {
      height: calc(#{$btn-height} - 10px);
    }
    &-lg {
      height: calc(#{$btn-height} + 5px);
    }
    svg {
      margin-right: 8px;
      @include for-phone-only {
        margin-right: 0px;
      }
    }
    &.btn-primary {
      color: $secondary-color;
    }
    &.btn-outline-tertiary {
      border-color: $input-border-color;
      color: $font-color;
    }
    &-gray {
      background: $gray-9;
      color: $font-color;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-group {
    .btn-sm {
      padding: 0.5rem 1rem;
    }
  }

  //Filter Card
  .filter-card {
    background: $gray-9;
    border-radius: 0;
    padding: $blockquote-padding;
    animation-name: height;
    animation-duration: 200ms;
    // * {
    //   animation-name: height;
    //   animation-duration: 200ms;
    // }

    .filter-form-group {
      display: flex;
      @include for-phone-only {
        flex-direction: column;
      }
      .filter-item {
        display: grid;
        flex-grow: 1;
        grid-gap: 0.8rem;
        grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
        @include for-phone-only {
          display: flex;
          flex-basis: 50%;
          flex-wrap: wrap;
          div {
            min-width: 100%;
          }
        }
      }
      .filter-action {
        margin-left: 15px;
        margin-top: 23px;
        @include for-phone-only {
          margin-left: 0px;
          margin-top: 15px;
          button {
            flex: 1;
            justify-content: center;
          }
        }
      }
    }
  }

  //Date Picker
  .react-datepicker-wrapper {
    width: 100%;
  }

  // React Select
  .custom-select-btn {
    padding: 0 !important;
    border: 1px solid $input-border-color;
    span {
      line-height: 40px;
      text-align: left;
      height: 40px;
      padding: 0 20px;
      font-weight: normal;
      color: $font-color;
      margin: 0;
      &:nth-child(2) {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .react-custom-select-container {
    position: relative;
    .react-select__control {
      position: absolute;
      width: calc(100% - 24px);
      top: 12px;
      left: 12px;
      z-index: 2;
      &.react-select__indicators {
        padding: 0 10px;
      }
    }
    .react-select__menu {
      padding-top: 55px;
      margin-top: 0;
      border-radius: 0 0 2px 2px;
      color: $font-color;
      .react-select__option {
        padding: 10px 12px;
        border-bottom: 1px solid $gray-9;
        cursor: pointer;
        &--is-focused {
          background: #f6f6f6;
        }
        &--is-selected {
          background: $primary-color;
        }
      }
    }
  }

  .react-select-container {
    &.add-products {
      .react-select__control {
        min-height: 54px;
        width: 100%;
        border: 0;
        align-items: flex-start;
        .react-select__value-container {
          padding: 2px 8px;
        }
        .react-select__indicators {
          display: none;
        }
      }
    }
    &.receive-product-select {
      min-width: 100%;
    }
    .react-select__control {
      min-height: calc(#{$input-height} + 2px);
      border-color: $input-border-color !important;
      color: $input-font-color;
      font-size: $input-font-size !important;
      border-radius: 2px;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      .react-select__value-container {
        padding: 2px 20px;
        .react-select__placeholder {
          color: $input-placeholder;
          font-size: $input-font-size;
        }
      }
    }
    .react-select__menu {
      margin-top: 0;
      border-radius: 0 0 2px 2px;
      color: $font-color;
      .react-select__option {
        padding: 10px 12px;
        border-bottom: 1px solid $gray-9;
        cursor: pointer;
        &--is-focused {
          background: #f6f6f6;
        }
        &--is-selected {
          background: $primary-color;
        }
      }
    }
  }

  //Inward Product
  .inwardProduct {
    border: 1px solid $input-border-color;
    border-radius: 4px;
    thead {
      tr {
        th {
          background: $gray-8;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: top;
          padding: 4px 8px;
          position: relative;
          .delete-table-row {
            right: 5px;
          }
          textarea {
            min-height: inherit;
          }
        }
      }
    }
  }
  //Add Vendor Modal
  .modal {
    &.inventory-modal {
      .modal-content {
        border-radius: 2px;
        border: 0;
      }
      .modal-body {
        background: $gray-9;
      }
    }
    &.addvendor-modal {
      width: 700px;
      max-width: 700px;
      height: 100vh;
      max-height: inherit;
      margin: 0;
      position: absolute;
      right: 0;
      border-radius: 0;
      left: auto;
      @include for-phone-only {
        width: 100%;
      }
      .modal-content {
        height: 100vh;
        max-height: inherit;
        border-radius: 0;
        border: 0;
        .modal-header {
          border-radius: 2px 0 0 2px;
          padding: 30px;
          @include for-phone-only {
            padding: 15px;
          }
        }
        .modal-body {
          background: $gray-9;
          padding: 30px;
          @include for-phone-only {
            padding: 15px;
          }
        }
      }
    }
  }

  .inventory-small-modal {
    .modal-content {
      border-radius: 2px;
      border: 0;
    }
    .modal-body {
      background: #f5f5f5;
      padding: 30px;
    }
  }

  //contact-person
  .contact-person {
    display: flex;
    .contact-person-group {
      display: grid;
      flex-grow: 1;
      grid-gap: 0.8rem;
      grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    }
  }

  // View Details
  .column {
    position: relative;
    &.list-column {
      min-width: 380px;
      max-width: 300px;
      &.list-column-small {
        min-width: 300px;
        max-width: 300px;
      }
    }
    &.details-column {
      flex: 1;
    }
    .column-header {
      padding: 20px 15px;
      border-bottom: 1px solid $input-border-color;
      @include for-phone-only {
        flex-direction: column;
        align-items: flex-start !important;
        h2 {
          margin-bottom: 15px;
          ~ .d-flex {
            flex-wrap: wrap;
            .btn-close {
              position: absolute;
              right: 0;
              top: 20px;
            }
            > span,
            > div,
            > button {
              margin-bottom: 8px;
              flex-grow: 1;
            }

            > div {
              > button {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .column-body {
      &.custom-scrollbar {
        height: calc(100vh - 167px);
        overflow: auto;
        &.with-footer {
          padding-bottom: 100px;
        }
      }
    }
    .column-footer {
      padding: 20px 15px;
      border-top: 1px solid $input-border-color;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: white;
      box-shadow: 0 -4px 5px -3px rgba(86, 106, 168, 0.17);
    }
    .column-box {
      padding: 15px 15px;
      border-bottom: 1px solid $input-border-color;
    }
  }

  .pdf-view {
    box-shadow: 0 0 6px $gray-5;
    padding: 0 0.4in 0 0.55in;
    position: relative;
  }

  //Dropdown
  .dropdown {
    border-radius: 2px;
    .dropdown-toggle {
      display: flex;
      align-items: center;
      border: 1px solid $input-border-color;
    }
    .btn-dropdown-sm {
      padding: 0.5rem 1.25rem;
      line-height: 18px;
      height: 30px;
    }
    .dropdown-toggle::after {
      content: "\f107";
      font: normal normal normal 16px/1 FontAwesome;
      border: 0;
      margin-left: 0.5rem;
      font-weight: 600;
    }
    .dropdown-menu {
      padding: 0;
      border-radius: 0;
      border: 1px solid $input-border-color;
      box-shadow: 0px 2px 8px rgba(86, 106, 168, 0.17);
      .dropdown-item {
        padding: 8px 15px;
        color: #333333;
        font-size: 14px;
        border-bottom: 1px solid $gray-9;
        &:hover {
          background-color: $gray-9;
        }
        &:active,
        &:focus {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }

  //Full width calendar
  .full-width-calendar {
    .react-datepicker,
    .react-datepicker__month-container {
      width: 100%;
    }
  }

  //highlighted-row
  .highlighted-row {
    background: $body;
    padding: 15px 30px;
    @include for-phone-only {
      padding: 15px;
      .right {
        width: 100%;
      }
    }
  }

  .view-body {
    padding: 15px 30px;
    @include for-phone-only {
      padding: 15px;
      .col-md-6 ~ .col-md-6 {
        margin-top: 15px;
      }
    }
    .address {
      padding: 20px 0 0px;
    }
    .history-list {
      .history__row {
        display: flex;
        position: relative;
        .date__column {
          width: 180px;
          padding-right: 5px;
          padding-top: 5px;
        }
        .comment__column {
          width: calc(100% - 180px);
          .comment__media {
            display: flex;
            align-items: flex-start;
            .history__icon {
              background: #f6fbff;
              border: 1px solid #ddd;
              position: relative;
              z-index: 2;
              border-radius: 50%;
              width: 28px;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .history__details {
          font-size: 14px;
          color: #424242;
        }
        &:last-child {
          &::before {
            bottom: 100%;
          }
        }
        &::before {
          content: "";
          // border-left: 1px solid #ddd;
          position: absolute;
          left: 194px;
          top: 0;
          bottom: 0;
        }
      }
    }
  }

  //
  table {
    // .react-select__menu{
    //   position: relative !important;
    // }
    .delete-table-row {
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
    }
    &.receive-item-table {
      table-layout: fixed;
      tr {
        td,
        th {
          padding: 0.5rem;
          position: relative;
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid $input-border-color;
          .react-select__value-container {
            padding: 2px 8px !important;
          }
        }
      }
    }
    &.viewpo-table {
      thead {
        tr {
          th {
            background: $gray-9;
          }
        }
      }
      &:last-child {
        td,
        th {
          border-bottom: 1px solid $input-border-color !important;
        }
      }
    }
    th,
    td {
      border-color: $input-border-color !important;
    }
  }

  //table-product-view
  .table-product-view {
    display: flex;
    .product-thumb {
      min-width: 45px;
      max-width: 45px;
      position: relative;
      flex: 1;
      div {
        border: 1px solid $input-border-color;
        position: relative;
        padding-top: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }
    .small-description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .product-thumbnail {
    min-width: 45px;
    max-width: 45px;
    position: relative;
    flex: 1;
    div {
      border: 1px solid $input-border-color;
      position: relative;
      padding-top: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
  }

  .status-ribbon {
    position: absolute;
    padding: 0.5rem;
    left: 0;
    top: 10px;
    border-radius: 0 0.156rem 0.156rem 0;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    &:after,
    &:before {
      position: absolute;
      content: "";
      display: block;
    }
    &:before {
      width: 0.469em;
      height: 100%;
      padding: 20px 3px;
      top: 0;
      left: -7px;
      background: inherit;
      border-radius: 0.313rem 0 0 0.313rem;
    }
    &:after {
      width: 0.313em;
      height: 0.313em;
      background: inherit;
      bottom: -0.313em;
      left: -0.313em;
      border-radius: 0.313em 0 0 0.313em;
      box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
    }
  }

  .section-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5%;
    background-color: #fff;
    z-index: 9;
  }

  .content-loader {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding-top: 5%;
    background-color: rgba(0, 0, 0, 0.01);
    z-index: 9;
    &.table-loader {
      position: absolute;
      top: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
    }
  }

  // Notification
  .notification {
    background: white;
    border-radius: 2px;
    padding: 16px 12px;
    box-shadow: 0px 2px 8px rgba(86, 106, 168, 0.17);
    margin-bottom: 20px;
    color: $font-color;
    &.icon {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        padding: 2px;
      }
    }
    &.success {
      border: 1px solid $success-color;
      &.icon {
        svg {
          background: $success-color;
        }
      }
    }
    &.warning {
      border: 1px solid $warning-color;
      &.icon {
        svg {
          background: $warning-color;
          padding: 0;
          circle {
            color: $warning-color;
          }
          line {
            color: white;
          }
        }
      }
    }
    &.light-warning {
      background: #fffbe6;
      &.icon {
        svg {
          background: $warning-color;
          padding: 0;
          circle {
            color: $warning-color;
          }
          line {
            color: white;
          }
        }
      }
    }
  }

  .total-section {
    border-radius: 2px;
  }

  .dashboard {
    .card {
      box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      overflow: hidden;
    }
    .dashboard-section {
      background: $gray-7;
      margin-top: -30px;
      padding: 30px 30px 0;
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 30px;
      @include for-phone-only {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 0;
        padding: 15px 15px 0;
      }
      .col-md-3,
      .col-md-4 {
        padding-left: 7.5px;
        padding-right: 7.5px;
        .card-body {
          padding: 1.5rem;
          border-radius: 4px;
          box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.05);
          .icons-widgets {
            .text-center {
              width: 54px;
              height: 54px;
              border-radius: 100%;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          h3 {
            color: $font-color;
            font-size: 2.1428rem;
          }
        }
      }
      .inventory-summary {
        .card-body {
          padding: 15px;
          h6 {
            max-width: 40%;
            line-height: 1.5;
          }
          .box-counter {
            max-width: 60%;
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            justify-content: flex-end;
            padding-left: 15px;
            h4 {
              font-size: 1.5rem;
              line-height: 1;
              margin-bottom: 0;
              font-weight: 600;
            }
          }
        }
      }
    }
    .dashboard-section-bottom {
      .col-lg-3,
      .col-lg-4,
      .col-lg-5,
      .col-lg-6,
      .col-lg-7,
      .col-lg-8,
      .col-lg-9,
      .col-lg-12 {
        padding-left: 7.5px;
        padding-right: 7.5px;
        .card {
          margin-bottom: 15px;
        }
        .card-header {
          padding: 15px;
        }
        .card-body {
          padding: 15px;
          border-radius: 4px;
          box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.05);
          .row {
            margin-left: -7.5px;
            margin-right: -7.5px;
          }
          .icons-widgets {
            .text-center {
              width: 54px;
              height: 54px;
              border-radius: 100%;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .widget-cards {
            padding: 15px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .table-with-img {
    tr {
      td {
        img {
          max-width: 30px;
        }
      }
    }
  }

  .valign-middle {
    tr {
      td,
      th {
        vertical-align: middle;
      }
    }
  }

  .card-separator {
    padding: 20px;
    border-bottom: 1px dashed $input-border-color;
    &:last-child {
      border: 0;
    }
  }

  .stistics-counter {
    font-size: 2.1428rem;
  }

  .card-header.small-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .daterangepicker {
    margin-top: -54px;
    &.rtl {
      .ranges {
        ul {
          li {
            text-align: right;
          }
        }
      }
    }
    .ranges {
      ul {
        li {
          display: block;
          font-size: 14px;
          padding: 12px;
          &.active {
            background: $primary-color;
          }
        }
      }
    }
    .drp-calendar {
      .calendar-table {
        border: 1px solid #aeaeae !important;
        border-radius: 4px;
        overflow: hidden;
        padding: 0 !important;
        th,
        td {
          border: 0;
          border-radius: 0;
          padding: 0.25rem;
        }
        thead {
          background: #f0f0f0;
          border-bottom: 1px solid #aeaeae;
        }
      }
    }
  }

  .avatar-icon {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      padding: 0;
      margin: 0;
    }
    &.w-44 {
      width: 44px;
      height: 44px;
    }
  }

  .profile-name {
    width: 64px;
    height: 60px;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .react-tabs {
    .user-management.nav-tabs {
      border: 0 !important;
      .nav-link {
        border: 0 !important;
        position: relative;
        &:first-child {
          padding-left: 0;
          &::after {
            width: 1px;
            content: "";
            height: 15px;
            border-right: 1px solid #eee;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        h3 {
          color: #666 !important;
          @include for-phone-only {
            font-size: 18px;
          }
        }
        &.react-tabs__tab--selected {
          border: 0 !important;
          h3 {
            color: #000 !important;
          }
        }
      }
    }
  }
  .dialog-hidden {
    display: none;
  }

  .table-responsive {
    overflow: auto;
    table-layout: auto;
  }

  .responsive-m-table {
    @include for-phone-only {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  }

  /*=====================
  Inventory CSS End
==========================*/
}

@keyframes height {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 350px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

div.is-disabled {
  pointer-events: none;
  opacity: 1;
  button.form-control {
    background-color: #e9ecef !important;
  }
}
