@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/open-sans/OpenSans-Light.eot");
  src:
    url("../../fonts/open-sans/OpenSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/open-sans/OpenSans-Light.woff2") format("woff2"),
    url("../../fonts/open-sans/OpenSans-Light.woff") format("woff"),
    url("../../fonts/open-sans/OpenSans-Light.ttf") format("truetype"),
    url("../../fonts/open-sans/OpenSans-Light.svg#OpenSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/open-sans/OpenSans-Bold.eot");
  src:
    url("../../fonts/open-sans/OpenSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/open-sans/OpenSans-Bold.woff2") format("woff2"),
    url("../../fonts/open-sans/OpenSans-Bold.woff") format("woff"),
    url("../../fonts/open-sans/OpenSans-Bold.ttf") format("truetype"),
    url("../../fonts/open-sans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/open-sans/OpenSans-Semibold.eot");
  src:
    url("../../fonts/open-sans/OpenSans-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/open-sans/OpenSans-Semibold.woff2") format("woff2"),
    url("../../fonts/open-sans/OpenSans-Semibold.woff") format("woff"),
    url("../../fonts/open-sans/OpenSans-Semibold.ttf") format("truetype"),
    url("../../fonts/open-sans/OpenSans-Semibold.svg#OpenSans-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/open-sans/OpenSans.eot");
  src:
    url("../../fonts/open-sans/OpenSans.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/open-sans/OpenSans.woff2") format("woff2"),
    url("../../fonts/open-sans/OpenSans.woff") format("woff"),
    url("../../fonts/open-sans/OpenSans.ttf") format("truetype"),
    url("../../fonts/open-sans/OpenSans.svg#OpenSans") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Arabic Fonts

@font-face {
  font-family: "Janna LT";
  src: url("../../fonts/arabic-font/JannaLT-Regular.eot");
  src:
    url("../../fonts/arabic-font/JannaLT-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/arabic-font/JannaLT-Regular.woff2") format("woff2"),
    url("../../fonts/arabic-font/JannaLT-Regular.woff") format("woff"),
    url("../../fonts/arabic-font/JannaLT-Regular.ttf") format("truetype"),
    url("../../fonts/arabic-font/JannaLT-Regular.svg#JannaLT-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Janna LT";
  src: url("../../fonts/arabic-font/JannaLT-Bold.eot");
  src:
    url("../../fonts/arabic-font/JannaLT-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/arabic-font/JannaLT-Bold.woff2") format("woff2"),
    url("../../fonts/arabic-font/JannaLT-Bold.woff") format("woff"),
    url("../../fonts/arabic-font/JannaLT-Bold.ttf") format("truetype"),
    url("../../fonts/arabic-font/JannaLT-Bold.svg#JannaLT-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
