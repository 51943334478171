.custom-theme {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $grey-about;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(5, 3, 3, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer;
}

// rtl css

body {
  &.rtl {
    direction: rtl;
    text-align: right;
    font-family: "Janna LT";

    a,
    span,
    li,
    p,
    input,
    textarea,
    button,
    div {
      font-weight: normal !important;
    }

    .f-w-900,
    .f-w-600,
    .fw-600,
    .fw-900,
    .font-weight-bolder,
    .font-weight-bold,
    b,
    h1,
    h2,
    h3,
    h4,
    .page-title,
    .modal-title {
      font-weight: bold !important;
    }

    .m-r-15 {
      margin-left: 15px;
      margin-right: unset;
    }

    .mr-2,
    .mx-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }

    .custom-select {
      padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    }

    .pl-0,
    .px-0 {
      padding-right: 0 !important;
      padding-left: unset !important;
    }

    .m-r-10 {
      margin-left: 10px;
      margin-right: unset;
    }

    .radio_animated,
    .checkbox_animated {
      margin: 0 0 0 1rem;
    }

    .digital-add {
      .form-group {
        .radio_animated {
          margin-left: 8px;
          margin-right: unset;
        }
      }
    }

    .needs-validation {
      .form-group {
        text-align: right;

        .checkbox {
          padding-right: 0;
          padding-left: unset;
        }
      }

      .permission-block {
        .attribute-blocks {
          padding-right: 15px;
          padding-left: unset;
          text-align: right;

          .row {
            padding-right: 20px;
            padding-left: unset;
          }
        }
      }

      .checkbox {
        label {
          padding-right: 16px;
          padding-left: unset;

          &::before {
            right: 0;
            left: unset;
            margin-left: unset;
            margin-right: -16px;
          }
        }

        right: 0;
        margin-left: -16px;
      }

      .radio-animated {
        label {
          margin-left: 20px;
          margin-right: unset;
        }
      }
    }

    .modal-footer {
      > :not(:last-child) {
        margin-left: 0.25rem;
        margin-right: unset;
      }
    }

    .jsgrid-button {
      + .jsgrid-button {
        margin-right: 5px;
        margin-left: unset;
      }
    }

    .custom-theme {
      left: 0;
      right: unset;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .text-right {
      text-align: left !important;
    }

    .br-wrapper {
      text-align: right;
    }

    .card {
      .card-header {
        .card-header-right {
          left: 20px;
          right: unset;

          .card-option {
            text-align: left;

            &.list-unstyled {
              padding-right: 0;
              padding-left: unset;
            }
          }
        }
      }
    }

    code[class*="language-"],
    pre[class*="language-"] {
      text-align: right;
    }

    .code-box-copy__btn {
      right: unset;
      left: 11px;
    }

    table {
      thead,
      tbody {
        tr {
          th,
          td {
            text-align: right;
          }
        }
      }
    }

    .pull-right {
      float: left;
    }

    .status-circle {
      right: 40px;
      left: unset;
    }

    .right-sidebar {
      &.show {
        left: 0;
        right: unset;
      }

      .friend-list-search {
        .fa {
          left: 35px;
          right: unset;
        }
      }

      .chat-box {
        .about {
          float: right;
          padding-right: 10px;
          padding-left: unset;
          text-align: right;
        }

        .friend-list {
          text-align: right;
        }

        .user-image {
          float: right;
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }

    .nav-menus {
      .search-form {
        input {
          padding: 10px 70px 10px 10px;
        }
      }
    }

    ul {
      &.notification-dropdown {
        &.onhover-show-div {
          li {
            text-align: right;
          }
        }
      }
    }

    // sidebar main page
    .page-wrapper {
      .page-body-wrapper {
        .page-sidebar {
          .sidebar-user {
            h6,
            p {
              text-align: center;
            }
          }

          .sidebar-menu {
            li {
              text-align: right;

              &.active {
                > .sidebar-submenu {
                  margin-right: 15px;
                  margin-left: unset;

                  svg {
                    transform: rotate(180deg);
                  }
                }

                a {
                  &::after {
                    right: -20px;
                    border-radius: 2px 0px 0px 2px;
                  }
                }
              }

              a {
                &:hover,
                &.active {
                  &::after {
                    right: -20px;
                    border-radius: 2px 0px 0px 2px;
                  }
                }
              }

              .fa-angle-right {
                transform: rotate(180deg);
              }
            }

            .sidebar-submenu {
              > li {
                > a {
                  padding-right: 10px;
                  padding-left: unset;

                  > .fa-circle {
                    right: 0;
                    left: unset;
                  }
                }
              }
            }

            .sidebar-header {
              svg {
                margin-right: unset;
                margin-left: 14px;
              }
            }
          }

          ~ .page-body {
            margin-right: $sidebar-width;
            margin-left: unset;
          }

          &.open {
            // margin-right: calc(-260px);
            // margin-left: unset;
            .sidebar .sidebar-menu .sidebar-header svg {
              margin-left: 0;
            }

            @include for-phone-only {
              left: auto;
              right: 0;
            }

            ~ .page-body,
            ~ footer {
              margin-right: $small-menu-width;

              @include for-phone-only {
                margin-right: 0;
              }
            }
          }
        }

        .page-header {
          .row {
            h3 {
              text-align: right;
            }
          }
        }

        footer {
          margin-right: $sidebar-width;
          margin-left: unset;

          p {
            i {
              margin-right: 5px;
              margin-left: unset;
            }
          }
        }
      }
    }

    // page main header
    .page-main-header {
      // margin-right: $sidebar-width;
      // margin-left: unset;
      .main-header-right {
        .nav-right {
          text-align: left;

          .profile-dropdown {
            left: 10px;
            right: inherit;

            li {
              svg {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }

          > ul {
            > li {
              &:before {
                right: 0;
                left: unset;
              }

              .dot {
                left: 17px;
                right: unset;
              }

              .media {
                .dotted-animation {
                  left: -3px;
                  right: unset;

                  .main-circle {
                    left: 6px;
                    right: unset;
                  }

                  .animate-circle {
                    left: -4px;
                    right: unset;
                  }
                }
              }

              &.onhover-dropdown {
                &:hover {
                  .onhover-show-div {
                    &:before {
                      left: 10px;
                      right: unset;
                    }

                    &:after {
                      left: 10px;
                      right: unset;
                    }
                  }
                }

                &:last-child {
                  padding-left: 0;
                  padding-right: 20px;
                  // border-right: none;
                }
              }

              &:first-child {
                border-right: none;

                .search-form {
                  .form-group {
                    &:after {
                      right: 22px;
                      left: unset;
                    }

                    &:before {
                      right: 51px;
                      left: unset;
                    }
                  }
                }
              }

              &:last-child {
                padding-right: 20px;
                padding-left: 0;
              }

              text-align: right;
              border-left: 0;
              border-right: 1px solid $input-border-color;

              i {
                &.ml-2 {
                  margin-right: 0.5rem !important;
                  margin-left: unset !important;
                }
              }
            }
          }

          .language-dropdown {
            text-align: right;

            li {
              a,
              span {
                i {
                  margin-left: 10px;
                }
              }
            }
          }

          .profile-dropdown {
            li {
              a {
                svg {
                  margin-right: unset;
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .mobile-sidebar {
          padding-right: 20px;
        }
      }

      &.open {
        margin-right: 0;
      }
    }

    ul {
      &.notification-dropdown {
        &.onhover-show-div {
          right: initial;
          left: -30px;
          padding: 0;

          &:before {
            right: inherit !important;
            left: 35px !important;
          }

          &:after {
            right: inherit !important;
            left: 35px !important;
          }

          li {
            margin-right: 0 !important;

            span {
              &.badge {
                margin-left: unset !important;
              }

              svg {
                margin-right: unset;
                margin-left: 10px;
              }
            }

            .notification-icon {
              margin-left: 20px;
              margin-right: unset;
            }
          }
        }
      }
    }

    .owl-carousel {
      direction: ltr;
    }

    // default page
    .media {
      .media-body {
        text-align: right;
      }
    }

    .order-graph {
      .order-graph-bottom {
        h6 {
          margin-right: 15px !important;
          margin-left: 30px;
        }
      }
    }

    .latest-order-table {
      .btn {
        float: right;
      }
    }

    // product
    .product-physical {
      table {
        tr {
          th,
          td {
            &:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }

    .product-page-details,
    .product-right {
      .color-variant,
      .size-box {
        text-align: right;
      }
    }

    .add-product-form {
      .qty-box {
        .input-group {
          .input-group-append {
            .btn {
              border-right: none !important;
              border-left: 1px solid $round-border !important;
            }
          }

          .btn-primary {
            &.bootstrap-touchspin-down {
              border-left: none !important;
              border-right: 1px solid $round-border !important;
            }
          }
        }
      }

      .offset-sm-4 {
        text-align: right;
      }
    }

    .timer {
      float: right;

      ~ .m-t-15 {
        clear: both;
        text-align: right;
        padding-top: 15px;
      }

      span {
        .padding-l {
          right: 35px;
          left: unset;
          padding-right: 0;
        }
      }
    }

    .product-list,
    .report-table {
      table {
        tr {
          td,
          th {
            text-align: right !important;

            &:last-child {
              text-align: center !important;
            }
          }
        }
      }
    }

    .dropzone {
      .dz-preview {
        .dz-error-mark,
        .dz-success-mark {
          left: unset;
          right: 48%;
          margin-right: -27px;
          margin-left: unset;
        }
      }

      &.dz-clickable {
        .dz-message {
          * {
            text-align: center;
          }
        }
      }
    }

    .digital-add {
      .col-form-label {
        display: block;
        text-align: right;
      }
    }

    // datatables
    .dataTables_wrapper {
      .dataTables_filter {
        input[type="search"] {
          margin-right: 10px;
          margin-left: unset;
        }
      }

      table {
        &.dataTable {
          .vendor-list {
            img {
              margin-left: 20px;
              margin-right: unset;
            }
          }
        }
      }
    }

    .category-table {
      table {
        tr {
          td,
          th {
            &:nth-child(3) {
              text-align: right;
            }
          }
        }
      }
    }

    .media-table {
      table {
        tr {
          td,
          th {
            &:last-child {
              text-align: right !important;
            }
          }
        }
      }
    }

    // profile page
    .profile-details {
      h5 {
        text-align: center;
      }
    }

    .account-setting {
      text-align: right;
    }

    // Bootstrap
    .text-left {
      text-align: right !important;
    }

    .rtl-start-flex {
      .justify-content-end {
        justify-content: flex-start !important;
      }
    }

    .text-right {
      text-align: left !important;
    }

    .pl-0,
    .px-0 {
      padding-left: unset;
      padding-right: 0 !important;
    }

    .pl-1,
    .px-1 {
      padding-left: unset;
      padding-right: 0.25rem !important;
    }

    .pl-2,
    .px-2 {
      padding-left: unset;
      padding-right: 0.5rem !important;
    }

    .pl-3,
    .px-3 {
      padding-left: unset;
      padding-right: 1rem !important;
    }

    .pl-4,
    .px-4 {
      padding-left: unset;
      padding-right: 1.5rem !important;
    }

    .pl-5,
    .px-5 {
      padding-left: unset;
      padding-right: 1.5rem !important;
    }

    .pr-0,
    .px-0 {
      padding-left: 0 !important;
      padding-right: unset;
    }

    .pr-1,
    .px-1 {
      padding-left: 0.25rem !important;
      padding-right: unset;
    }

    .pr-2,
    .px-2 {
      padding-left: 0.5rem !important;
      padding-right: unset;
    }

    .pr-3,
    .px-3 {
      padding-left: 1rem !important;
      padding-right: unset;
    }

    .pr-4,
    .px-4 {
      padding-left: 1.5rem !important;
      padding-right: unset;
    }

    .pr-5,
    .px-5 {
      padding-left: 1.5rem !important;
      padding-right: unset;
    }

    .ml-0,
    .mx-0 {
      margin-right: 0 !important;
      margin-left: unset;
    }

    .ml-1,
    .mx-1 {
      margin-right: 0.25rem !important;
      margin-left: unset;
    }

    .ml-2,
    .mx-2 {
      margin-right: 0.5rem !important;
      margin-left: unset;
    }

    .ml-3,
    .mx-3 {
      margin-right: 1rem !important;
      margin-left: unset;
    }

    .ml-4,
    .mx-4 {
      margin-right: 1.5rem !important;
      margin-left: unset;
    }

    .ml-5,
    .mx-5 {
      margin-right: 3rem !important;
      margin-left: unset;
    }

    .mr-0,
    .mx-0 {
      margin-right: unset;
      margin-left: 0 !important;
    }

    .mr-1,
    .mx-1 {
      margin-right: unset;
      margin-left: 0.25rem !important;
    }

    .mr-3,
    .mx-3 {
      margin-right: unset !important;
      margin-left: 1rem !important;
    }

    .mr-4,
    .mx-4 {
      margin-right: unset;
      margin-left: 1.5rem !important;
    }

    .mr-5,
    .mx-5 {
      margin-right: unset;
      margin-left: 3rem !important;
    }

    //Form Element
    .dropdown .dropdown-toggle::after {
      margin-left: inherit;
      margin-right: 0.5rem !important;
    }

    //Table
    table {
      tr {
        td,
        th {
          text-align: right !important;

          &.no-record {
            text-align: center !important;
            border: 0 !important;
          }
        }
      }
    }

    // .table-inventory thead tr th ::after,
    // .table-inventory thead tr th ::before {
    //   left: 10px;
    //   right: inherit !important;
    // }

    .table-pagination {
      .btn {
        i {
          transform: rotate(180deg);
        }
      }
    }

    //Inward view
    .status-ribbon {
      right: 0;
      left: inherit;
      border-radius: 0.156rem 0 0 0.156rem;

      &::before {
        left: unset;
        right: -7px;
        border-radius: 0 0.313rem 0.313rem 0;
      }

      &::after {
        right: -0.313em;
        left: unset;
      }
    }

    // Filter card
    .filter-card .filter-form-group .filter-action {
      margin-right: 15px;
      margin-left: 0 !important;

      .btn {
        svg {
          margin-left: 0;
        }
      }
    }

    // Delete Row Icon
    table .delete-table-row {
      left: -5px;
      right: inherit;
    }

    .inwardProduct tbody tr td .delete-table-row {
      // left: 5px;
      right: inherit;
    }

    //Custom Select
    .custom-select-btn span {
      text-align: right;
    }

    //Notification
    .notification.icon svg {
      margin-left: 10px;
      margin-right: inherit;
    }

    //Footer
    .modal-footer {
      justify-content: flex-start;
    }

    .card-footer.justify-content-end {
      justify-content: flex-start !important;
    }

    //Button
    .btn svg {
      margin-left: 8px;
      margin-right: 0;
    }

    .border-right {
      border-left: 1px solid #dee2e6 !important;
      border-right: 0;
    }

    .border-left {
      border-right: 1px solid #dee2e6 !important;
      border-left: 0;
    }

    .btn-group {
      flex-direction: row-reverse;
    }

    .page-wrapper .page-main-header.open {
      // margin-right: $small-menu-width;
      margin-left: unset;

      @include for-phone-only {
        margin-right: 0;
      }
    }

    // Icon
    .rtl-icon {
      transform: rotate(-180deg);
    }

    //ltr-direction
    span {
      &.ltr-direction {
        display: inline-block;
      }
    }

    .ltr-direction {
      direction: ltr !important;
    }

    .modal {
      .addvendor-modal {
        left: 0;
        right: unset;
      }

      .status-model,
      .change-password-modal {
        .modal-header {
          .close {
            margin: -1rem auto -1rem -1rem;
          }
        }
      }
    }

    .modal-header .close {
      padding: 1rem 1rem;
      margin: -1rem auto -1rem -1rem;
    }

    // Date range picker
    .daterangepicker {
      .drp-calendar {
        &.left {
          float: right;
          padding: 8px 8px 8px 0px;
          border-right: 1px solid #ddd !important;

          .calendar-table {
            border-radius: 4px !important;

            th,
            td {
              text-align: center !important;
            }
          }
        }

        &.right {
          float: left;
          border-right: 0 !important;

          .calendar-table {
            border-radius: 4px !important;

            th,
            td {
              text-align: center !important;
            }
          }
        }

        .table-condensed {
          direction: rtl;

          thead {
            td,
            th {
              &.prev {
                span {
                  transform: rotate(-45deg);
                }
              }

              &.next {
                span {
                  transform: rotate(130deg);
                }
              }
            }
          }
        }
      }
    }

    .language-onhover-dropdown {
      cursor: pointer;
      position: absolute;
      left: 64px;
      right: inherit;
      top: 25px;
      padding: 4px 7px;

      &:before {
        display: none;
      }

      &:hover {
        .onhover-show-div {
          @extend %for-animated-hover-box;
          top: 42px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      .page-wrapper {
        .page-main-header {
          margin-right: 0;
        }

        .page-body-wrapper {
          .page-sidebar {
            ~ .page-body,
            ~ footer {
              margin-right: 0;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      .needs-validation {
        .form-group {
          .checkbox {
            padding-right: 15px;
            padding-left: unset;
          }
        }
      }

      .page-wrapper {
        .page-body-wrapper {
          footer {
            p {
              text-align: center;
            }
          }
        }
      }
    }

    @media (min-width: 1200px) {
      .offset-xl-3 {
        margin-right: 25%;
        margin-left: unset;
      }
    }

    // Bootstrap
    .ml-auto,
    .mx-auto {
      margin-right: auto !important;
    }

    //
    .text-left {
      text-align: right !important;
    }

    //.dashboard-section
    .dashboard-section {
      .inventory-summary {
        .card-body {
          .box-counter {
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            border-left: 0;
            padding-right: 15px;
            padding-left: 0;
          }
        }
      }

      .page-body-wrapper .page-sidebar .sidebar-menu > li > a i.fa-angle-right {
        transform: rotate(180deg);
      }
    }
  }
}
